import './App.css';

function App() {
  return (
    <div className="App">
     <p>vps test2</p>
    </div>
  );
}

export default App;
